import AboutOutscal from "./AboutOutscal.view";
import ContactUs from "./ContactUs.view";
import KeyLinks from "./KeyLinks.view";
import Company from "./Company.view";
import styles from "./Footer.module.css";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";

export default function Footer() {
  if (ClientAppManager.instance.inIFrame) {
    return null;
  }
  return (
    <section data-outscal-section className={styles["root"]}>
      <AboutOutscal />
      <Company />
      <KeyLinks />
      <ContactUs />
    </section>
  );
}
